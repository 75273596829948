import { Icon } from "@chakra-ui/react";

type Props = {
  fill?: string;
};

function TikTokIcon({ fill = "secondary.600" }: Props) {
  return (
    <Icon
      width="30"
      height="30"
      viewBox="0 0 100 100"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M50 100C22.4333 100 0 77.5667 0 50C0 22.4333 22.4333 0 50 0C77.5667 0 100 22.4333 100 50C100 77.5667 77.5667 100 50 100ZM50 6.66667C26.1 6.66667 6.66667 26.1 6.66667 50C6.66667 73.9 26.1 93.3333 50 93.3333C73.9 93.3333 93.3333 73.9 93.3333 50C93.3333 26.1 73.9 6.66667 50 6.66667Z"
        fill={fill}
      />
      <path
        d="M74.0333 44.6333C73.5667 44.6667 73.1 44.7 72.6333 44.7C67.5667 44.7 62.8333 42.1333 60.0667 37.9V61C60.0667 70.4333 52.4333 78.0667 43 78.0667C33.5667 78.0667 25.9333 70.4333 25.9333 61C25.9333 51.5667 33.5667 43.9333 43 43.9333C43.3667 43.9333 43.7 43.9667 44.0667 44V52.4333C39.3 51.8667 34.9667 55.2667 34.4 60.0667C33.8333 64.8333 37.2333 69.1667 42.0333 69.7333C42.3667 69.7667 42.7 69.8 43 69.8C47.8667 69.8667 51.9 66.0333 52.0667 61.2L52.1667 21.9333H60.2333C61 29.1667 66.8 34.8 74.0667 35.3333V44.7"
        fill={fill}
      />
    </Icon>
  );
}

// eslint-disable-next-line import/no-default-export
export default TikTokIcon;
